<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Admin Role</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm" iconPos="right" />
                </div>
            </div>
            <div class="card p-mt-4">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Admin Name : {{ this.localAdminName ? this.localAdminName : '-' }} </h6>
                    </span>
                </div>
                <DataTable :value="clientRoles" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" dataKey="ad1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <!-- <h5 class="p-m-0">Roles Of Vision Created Group</h5> -->
                        </template>
                        <template v-slot:right>
                            <Button label="Add Roles" icon="pi pi-plus" @click="toAddRoles" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data. Please wait... </template>
                    <Column field="id" header="Sr." headerStyle="width: 10%">
                        <template #body="{ index }">
                            {{ index+=1 }}
                        </template>
                    </Column>
                    <Column header="Employee Name" field="ad9" class="p-text-capitalize" headerStyle="width: 35%">
                    </Column>
                    <Column header="Assign Roles" field="ad28" headerStyle="width: 35%">
                        <template #body="{ data: { ad28 } }">
                            <div>
                                <span v-if="ad28 != '' && ad28 != null && ad28 != '0'">
                                    <span v-for="(value, index) in getAssignedRoles(ad28).split(',')" :key="index">
                                        <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts" :label="value" />
                                    </span>
                                </span>
                                <span v-else>-</span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                @click="editEmployeeRoleModalOpen(data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="deleteEmployeeRole(data)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <!-- Add Role Dialog starts here -->
    <Dialog v-model:visible="isAddRole" :style="{ width: '800px' }" :modal="true" class="p-fluid"
        header="Add Employee Role">
        <div style="position: relative" v-if="!showLoader">
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="employee">
                            Employee
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Dropdown id="roleName" class="p-text-capitalize" v-model="employee" :options="employeesList"
                            optionLabel="label" required="true" :class="{ 'p-invalid': isSubmitted && !roleName }"
                            placeholder="Select Employee" emptyMessage="No Records" appendTo="body" />
                        <small class="p-invalid p-error" v-if="v$.employee.$error">{{
                            v$.employee.$errors[0].$message
                        }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="roleName">
                            Role Name
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <!-- <Dropdown id="roleName" v-model="roleName" :options="roles" optionLabel="label" required="true"
                            :class="{ 'p-invalid': isSubmitted && !roleName }" placeholder="Select Role"
                            emptyMessage="No Records" appendTo="body" /> -->
                        <MultiSelect v-model="roleName" :options="roles" optionValue="value" optionLabel="label"
                            placeholder="Select Role" :filter="true" class="multiselect-custom" appendTo="body">
                        </MultiSelect>
                        <small class="p-invalid p-error" v-if="v$.roleName.$error">{{
                            v$.roleName.$errors[0].$message
                        }}</small>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Submit" :icon="`pi ${!showLoaderAddBtn ? 'pi-check' : 'pi-spin pi-spinner indigo-color'}`"
                class="p-button-text p-ml-auto" @click="addEmployeeRole" style="width: 90px;"></Button>
            <!-- <Button v-show="showLoaderAddBtn" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button> -->
        </template>
    </Dialog>
    <!-- Add Role Dialog ends here -->

    <!-- Edit Role Dialog starts here -->
    <Dialog v-model:visible="isEditEmployeeRole" header="Edit Employee Role" :style="{ width: '800px' }" :modal="true"
        class="p-fluid">
        <div style="position: relative" v-if="!showLoader">
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6 p-text-capitalize">
                        <label for="employee">
                            Employee
                            <span class="p-invalid p-error">*</span>
                        </label>

                        <Dropdown id="roleName" v-model="employee" :options="employeesList" optionLabel="label"
                            required="true" :class="{ 'p-invalid': isSubmitted && !roleName }" placeholder="Select Employee"
                            emptyMessage="No Records" appendTo="body" disabled />
                        <small class="p-invalid p-error" v-if="v$.employee.$error">{{
                            v$.employee.$errors[0].$message
                        }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="roleName">
                            Role Name
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <!-- <Dropdown id="roleName" v-model="roleName" :options="roles" optionLabel="label" required="true"
                            :class="{ 'p-invalid': isSubmitted && !roleName }" placeholder="Select Role"
                            emptyMessage="No Records" appendTo="body" /> -->
                        <MultiSelect v-model="roleName" :options="roles" optionValue="value" optionLabel="label"
                            placeholder="Select Role" :filter="true" class="multiselect-custom" appendTo="body">
                        </MultiSelect>
                        <small class="p-invalid p-error" v-if="v$.roleName.$error">{{
                            v$.roleName.$errors[0].$message
                        }}</small>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Submit" :icon="`pi ${!showLoaderAddBtn ? 'pi-check' : 'pi-spin pi-spinner indigo-color'}`"
                class="p-button-text p-ml-auto" @click="editEmployeeRole" style="width: 90px;"></Button>
            <!-- <Button v-show="showLoaderAddBtn" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px" appendTo="body"></Button> -->
        </template>
    </Dialog>
    <!-- Edit Role Dialog ends here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import { required, helpers } from '@vuelidate/validators';

export default {
    data() {
        return {
            clientRoles: [],
            v$: useValidate(),
            isAddRole: false,
            isSubmitted: false,
            employee: "",
            roleName: "",
            showLoader: false,
            expandedRows: [],
            loading: false,
            page_no: 0,
            totalRecords: 0,
            employeesList: [],
            roles: [],
            isEditEmployeeRole: false,
            showLoaderAddBtn: false,
            rowId: '',
            UserIdList: [],
            localAdminName: '',
        };
    },
    validations() {
        return {
            employee: { required: helpers.withMessage('Please select employee', required) },
            roleName: { required: helpers.withMessage('Please select role', required) },
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    async mounted() {
        const route = useRoute();
        this.routeParam = route.params.adminSubId;
        this.localAdminName = localStorage.localAdminName;
        this.loading = true;
        this.roles = [
            { label: 'Owner', value: "1" },
            { label: 'Admin', value: "2" },
            { label: 'Co-ordinater', value: "3" },
            { label: 'Billing', value: "4" },
        ];
        this.getAllAdminUserRoleDetails({ clientId: this.routeParam });
    },
    methods: {
        getAssignedRoles(val) {
            var res1 = val.split(',').map(Number);
            var res = Array.from(res1);
            let array = [];
            for (let i = 0; i < res.length; i++) {
                for (let j = 0; j < this.roles.length; j++) {
                    if (this.roles[j].value == res[i]) {
                        array.push(this.roles[j].label);
                    }
                }
            }
            return array.join(",");
        },
        async toAddRoles() {
            this.isAddRole = true;
            this.isSubmitted = false;
            this.employee = '';
            this.roleName = '';
            this.getAdminUsersNotHavingRoles();
            this.v$.$reset();
        },
        addEmployeeRole() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['employee'] = this.employee.value;
            fields['role'] = this.roleName;
            fields['clientId'] = this.routeParam;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.showLoaderAddBtn = true;
                this.ApiService.createUpdateAdminUserRole(fields).then((items) => {
                    if (items.status == 200) {
                        this.showLoaderAddBtn = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.getAllAdminUserRoleDetails({ clientId: this.routeParam });
                        this.isAddRole = false;
                        this.employee = '';
                        this.roleName = '';
                    } else {
                        this.showLoaderAddBtn = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        editEmployeeRoleModalOpen(data) {
            this.isEditEmployeeRole = true;
            this.employee = '';
            this.roleName = '';
            this.rowId = data.ad1;
            this.employeesList.push({
                label: data.ad9,
                value: data.ad1,
            });

            let ad9data = this.employeesList.filter(function (item) {
                return item.value == data.ad1;
            });
            if (ad9data.length > 0) {
                this.employee = ad9data[0];
            }
            let roleName = [...data.ad28];
            var i = 0;
            while (i < roleName.length) {
                if (roleName[i] === ',') {
                    roleName.splice(i, 1);
                } else {
                    ++i;
                }
            }
            let newArr = roleName;
            this.roleName = newArr;
        },
        editEmployeeRole() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['employee'] = this.employee.value;
            fields['role'] = this.roleName;
            fields['clientId'] = this.routeParam;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.showLoaderAddBtn = true;
                this.ApiService.createUpdateAdminUserRole(fields).then((items) => {
                    if (items.status == 200) {
                        this.showLoaderAddBtn = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.getAllAdminUserRoleDetails({ clientId: this.routeParam });
                        this.employeesList.pop();
                        this.isEditEmployeeRole = false;
                        this.employee = '';
                        this.roleName = '';
                    } else {
                        this.employeesList.pop();
                        this.showLoaderAddBtn = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        async getAllAdminUserRoleDetails(payload) {
            try {
                const result = await this.ApiService.getAllAdminUserRoleDetails(payload);
                this.clientRoles = result.data.result;
                this.totalRecords = result.data.count;
                this.UserIdList = result.data.ad1Values;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            } finally {
                this.loading = false;
            }
        },

        async getAdminUsersNotHavingRoles() {
            try {
                const params = { clientId: this.routeParam };
                ({ data: this.employeesList } = await this.ApiService.getAdminUsersNotHavingRoles(params));
            } catch ({ error }) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            }
        },
        async deleteEmployeeRole(data) {
            try {
                this.loading = true;
                const { ad1 } = data;
                const params = {
                    clientId: this.routeParam,
                    ad1
                };
                const { success, message } = await this.ApiService.removeAssignedRoles(params);
                if (success) {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
                    this.getAllAdminUserRoleDetails({ clientId: this.routeParam })
                }
            } catch ({ message }) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
            }
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getAllAdminUserRoleDetails({ page_no: event.page });
        },
        goToDashboard() {
            router.push({
                name: "admindashboard",
                params: { adminId: this.routeParam },
            });
        },
        goToPrevious() {
            router.push({
                name: "adminaddfund",
                params: { adminSubId: this.routeParam },
            });
        },
        goToNext() {
            router.push({
                name: "admindocument",
                params: { adminSubId: this.routeParam },
            });
        }
    }

};
</script>

<style scoped></style>
